import type { Cache } from "./Cache.ts"

import type { SerializeFrom } from "@remix-run/node"

import type { loader as PhotoJournalLoader } from "../routes/photo-journals/get-posts.server.ts"
import type { loader as SettingsLoader } from "../routes/settings.tsx"
import type { loader as TapLoader } from "../routes/tap.item-v2/loader.server.ts"

import { InMemoryCache } from "./in-memory-cache.ts"

type TapPage = SerializeFrom<TapLoader>
type PhotoJournalPage = SerializeFrom<PhotoJournalLoader>
type SettingsPage = SerializeFrom<SettingsLoader>

// 5 minutes
const CACHED_TTL = 1000 * 60 * 5

export class LoadersCache {
  private static instance: LoadersCache

  public static singleton(): LoadersCache {
    if (LoadersCache.instance === undefined) {
      LoadersCache.instance = new LoadersCache(
        new LoaderCache(new InMemoryCache(), "tap", CACHED_TTL),
        new LoaderCache(new InMemoryCache(), "photo-journal", CACHED_TTL),
        new LoaderCache(new InMemoryCache(), "settings", CACHED_TTL),
      )
    }

    return LoadersCache.instance
  }

  private constructor(
    public readonly tapLoader: LoaderCache<TapPage>,
    public readonly photoJournalLoader: LoaderCache<PhotoJournalPage>,
    public readonly settingsLoader: LoaderCache<SettingsPage>,
  ) {}
}

class LoaderCache<A> {
  constructor(
    private readonly cache: Cache<A>,
    private readonly cacheKey: string,
    private readonly cacheTtl: number,
  ) {}

  async set(value: A): Promise<void> {
    this.cache.set(this.cacheKey, value, this.cacheTtl)
  }

  async get(): Promise<A | undefined> {
    return this.cache.get(this.cacheKey)
  }

  async delete(): Promise<void> {
    this.cache.delete(this.cacheKey)
  }
}
