import type { Cache } from "./Cache.ts"

type Expireable<A> = { value: A; expiresAt?: Date }

export class InMemoryCache<A> implements Cache<A> {
  constructor(private data: Map<string, Expireable<A>> = new Map()) {}

  async set(key: string, value: A, ttlMs?: number) {
    const expiresAt = ttlMs === undefined ? undefined : new Date(Date.now() + ttlMs)
    this.data.set(key, { value, expiresAt })
  }

  async get(key: string) {
    const match = this.data.get(key)

    if (match === undefined) return undefined

    if (match.expiresAt === undefined) return match.value

    const isExpired = Date.now() > match.expiresAt.getTime()

    if (isExpired) {
      this.data.delete(key)
      return undefined
    }

    return match.value
  }

  async delete(key: string) {
    this.data.delete(key)
  }
}
